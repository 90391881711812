/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import loginbg from "images/bg-1.svg";
import MMLogo from "images/3MTT/3MM.png";
import { Path } from "utils/constant.data";
import Swal from "sweetalert2";
import {
  useRegisterFellowMutation,
  useRegisterProviderMutation,
  useValidateIDMutation,
} from "store/services/authAPI";
import {
  LocalGov,
  Nigerian_States,
  OrganisationTypes,
} from "utils/constant.data";
import { capitalize, formatHyphen } from "utils/strings";
import { replaceUnderscoresWithSpaces } from "utils/strings";

function Apply() {
  const [idType, setIdType] = useState("");
  const [LGAs, setLGAs] = useState([]);
  const [verify, setVerify] = useState({
    digitsValidated: false,
    digitsVerified: false,
  });

  const [registerFellow, { isLoading: registeringFellow }] =
    useRegisterFellowMutation();
  const [registerProvider, { isLoading: registeringProvider }] =
    useRegisterProviderMutation();

  const [validateID, { isLoading: validatingID }] = useValidateIDMutation();

  const handleSubmit = async (values) => {
    const { user_type, ...restValues } = values;
    const filteredValues = Object.keys(restValues).reduce((acc, key) => {
      if (
        restValues[key] !== null &&
        restValues[key] !== undefined &&
        restValues[key] !== ""
      ) {
        acc[key] = restValues[key];
      }
      return acc;
    }, {});

    const registerUser =
      values.user_type === "FELLOW" ? registerFellow : registerProvider;

    try {
      await registerUser(filteredValues).unwrap();
      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: "Check your email for more instructions",
      });
    } catch (error) {
      let errorTitle = "Error Occurred";
      let errorMessage = "An error occurred during registration";

      if (error.data && error.data.errors) {
        if (error.data.errors.non_field_errors) {
          errorMessage = error.data.errors.non_field_errors[0];
        } else {
          // If there are field-specific errors, join them into a single message
          errorMessage = Object.values(error.data.errors).flat().join(", ");
        }
      } else if (error.data && error.data.detail) {
        errorMessage = error.data.detail;
      }

      if (error.data && error.data.code) {
        errorTitle = replaceUnderscoresWithSpaces(error.data.code);
      }

      Swal.fire({
        title: errorTitle,
        text: errorMessage,
        iconHtml:
          '<i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>',
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "Try Again",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      user_type: "",
      first_name: "",
      last_name: "",
      id_type: "",
      nin: "",
      bvn: "",
      name: "",
      poc_fullname: "",
      poc_email: "",
      poc_phone: "",
      organisation_type: "",
      state: "",
      lga: "",
    },
    validationSchema: ApplicationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const handleInvalidID = (error) => {
      formik.setFieldValue("first_name", null);
      formik.setFieldValue("last_name", null);
      setVerify({
        digitsValidated: true,
        digitsVerified: false,
      });
      Swal.fire({
        title: "Error Occurred",
        text:
          error?.message ??
          `Invalid ${capitalize(formik.values?.id_type ?? "Value")}`,
        iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
        showCloseButton: true,
        customClass: {
          icon: "no-border",
        },
        confirmButtonText: "Try Again",
      });
    };

    const handleIDVerification = async () => {
      if (formik.values.nin_bvn && formik.values.nin_bvn.length === 11) {
        try {
          const data = await validateID({
            id_type: formik.values?.id_type,
            nin_bvn: formik.values?.nin_bvn,
          }).unwrap();

          const isValid = data?.data?.status ?? false;
          if (isValid) {
            const response = data?.data ?? null;
            setVerify({
              digitsValidated: true,
              digitsVerified: true,
            });
            formik.setFieldValue("first_name", response?.first_name);
            formik.setFieldValue("last_name", response?.last_name);
          } else {
            handleInvalidID({
              message: data?.data?.message,
            });
          }
        } catch (error) {
          handleInvalidID({
            message: "Error occured validating ID",
          });
        }
      }
    };

    handleIDVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.id_type, formik.values.nin_bvn, validateID]);

  return (
    <div className="vh-100">
      <div
        className="authincation h-100 p-meddle"
        style={{
          background:
            "linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(" +
            loginbg +
            ")",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100">
          <div
            className={`row justify-content-center h-100 align-items-center ${
              validatingID ? "disabled" : ""
            }`}
          >
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mm__logo mb-2">
                        <Link to={Path.LOGIN}>
                          <img src={MMLogo} alt="" />
                        </Link>
                      </div>

                      <h4 className="text-center mb-4 ">Sign Up</h4>

                      <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                          <label className="mb-1">
                            <strong>Applicant Type</strong>
                          </label>
                          <select
                            name="user_type"
                            id="user_type"
                            className="form-control"
                            {...formik.getFieldProps("user_type")}
                          >
                            <option value="">Sign up as</option>
                            <option value="FELLOW">A Fellow (Learner)</option>
                            <option value="PROVIDER">
                              An Applied Learning Cluster (Organization)
                            </option>
                          </select>
                          {formik.touched.user_type &&
                          formik.errors.user_type ? (
                            <div className="text-danger mt-2 fs-12">
                              {formik.errors.user_type}
                            </div>
                          ) : null}
                        </div>

                        {formik.values.user_type === "FELLOW" && (
                          <>
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>ID Type</strong>
                              </label>
                              <select
                                name="id_type"
                                id="id_type"
                                className="form-control"
                                {...formik.getFieldProps("id_type")}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  setIdType(e.target.value);
                                }}
                              >
                                <option value="">Select ID Type</option>
                                <option value="nin">NIN</option>
                                <option value="bvn">BVN</option>
                              </select>
                              {formik.touched.id_type &&
                              formik.errors.id_type ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.id_type}
                                </div>
                              ) : null}
                            </div>

                            {(idType === "nin" || idType === "bvn") && (
                              <div className="mb-3">
                                <label className="mb-1">
                                  <strong>
                                    {idType === "nin" && "NIN"}
                                    {idType === "bvn" && "BVN"}
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nin_bvn"
                                  name="nin_bvn"
                                  placeholder="1234567890"
                                  {...formik.getFieldProps("nin_bvn")}
                                />
                                {formik.touched.nin_bvn &&
                                formik.errors.nin_bvn ? (
                                  <div className="text-danger mt-2 fs-12">
                                    {formik.errors.nin_bvn}
                                  </div>
                                ) : null}
                              </div>
                            )}

                            {validatingID && (
                              <div className="mb-3">
                                <label className="mb-1">
                                  <span
                                    role="status"
                                    aria-hidden="true"
                                    className="spinner-border spinner-border-sm ms-2"
                                  ></span>{" "}
                                  Validating {capitalize(idType)} .........
                                </label>
                              </div>
                            )}

                            {/* 
                            {idType === "bvn" && (
                              <div className="mb-3">
                                <label className="mb-1">
                                  <strong>BVN</strong>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nin_bvn"
                                  name="nin_bvn"
                                  placeholder="1234567890"
                                  {...formik.getFieldProps("nin_bvn")}
                                />
                                {formik.touched.bvn && formik.errors.nin_bvn ? (
                                  <div className="text-danger mt-2 fs-12">
                                    {formik.errors.nin_bvn}
                                  </div>
                                ) : null}
                              </div>
                            )} */}

                            {verify?.digitsVerified && (
                              <>
                                <div className="mb-3">
                                  <label className="mb-1">
                                    <strong>First Name</strong>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="first_name"
                                    name="first_name"
                                    disabled
                                    placeholder="First name"
                                    {...formik.getFieldProps("first_name")}
                                  />
                                  {formik.touched.first_name &&
                                  formik.errors.first_name ? (
                                    <div className="text-danger mt-2 fs-12">
                                      {formik.errors.first_name}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <label className="mb-1">
                                    <strong>Last Name</strong>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="last_name"
                                    disabled
                                    name="last_name"
                                    placeholder="Last name"
                                    {...formik.getFieldProps("last_name")}
                                  />
                                  {formik.touched.last_name &&
                                  formik.errors.last_name ? (
                                    <div className="text-danger mt-2 fs-12">
                                      {formik.errors.last_name}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mb-3">
                                  <label className="mb-1">
                                    <strong>Email</strong>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="e.g. example@domainname.com"
                                    {...formik.getFieldProps("email")}
                                  />
                                  {formik.touched.email &&
                                  formik.errors.email ? (
                                    <div className="text-danger mt-2 fs-12">
                                      {formik.errors.email}
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {formik.values.user_type === "PROVIDER" && (
                          <>
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>Organisation Name</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Organisation name"
                                {...formik.getFieldProps("name")}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>POC Full Name</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="poc_fullname"
                                name="poc_fullname"
                                placeholder="POC full name"
                                {...formik.getFieldProps("poc_fullname")}
                              />
                              {formik.touched.poc_fullname &&
                              formik.errors.poc_fullname ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.poc_fullname}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>POC Email</strong>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="poc_email"
                                name="poc_email"
                                placeholder="e.g. example@domainname.com"
                                {...formik.getFieldProps("poc_email")}
                              />
                              {formik.touched.poc_email &&
                              formik.errors.poc_email ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.poc_email}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>POC Phone</strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="poc_phone"
                                name="poc_phone"
                                placeholder="POC phone"
                                {...formik.getFieldProps("poc_phone")}
                              />
                              {formik.touched.poc_phone &&
                              formik.errors.poc_phone ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.poc_phone}
                                </div>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <label className="mb-1">
                                <strong>Organisation Type</strong>
                              </label>
                              <select
                                name="organisation_type"
                                id="organisation_type"
                                className="form-control"
                                {...formik.getFieldProps("organisation_type")}
                              >
                                <option value="">
                                  Select Organisation Type
                                </option>
                                {OrganisationTypes.map((type) => {
                                  return (
                                    <option value={type}>
                                      {formatHyphen(type)}
                                    </option>
                                  );
                                })}
                              </select>
                              {formik.touched.organisation_type &&
                              formik.errors.organisation_type ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.organisation_type}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group mb-3">
                              <label className="mb-1">
                                <strong>State of training centre</strong>
                              </label>
                              <select
                                id="state"
                                name="state"
                                className="form-control"
                                onChange={(e) => {
                                  const selectedState = e.target.value;
                                  formik.setFieldValue("state", selectedState);
                                  formik.setFieldTouched("state", true);

                                  const _selectedLGAs =
                                    LocalGov.find(
                                      (val) =>
                                        val?.state?.toLowerCase() ===
                                        selectedState.toLowerCase()
                                    )?.lgas || [];

                                  setLGAs([..._selectedLGAs]);
                                }}
                              >
                                {Nigerian_States.map((state) => {
                                  return <option value={state}>{state}</option>;
                                })}
                              </select>
                              {formik.errors.state ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.state}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group mb-3">
                              <label className="mb-1">
                                <strong>Local Government</strong>
                              </label>

                              <select
                                id="lga"
                                name="lga"
                                className="form-control"
                                {...formik.getFieldProps("lga")}
                                onChange={(e) => {
                                  if (e.target.value?.length > 0) {
                                    formik.setFieldValue("lga", e.target.value);
                                    formik.setFieldError("lga", "");
                                  } else {
                                    formik.setFieldError(
                                      "lga",
                                      `Local Government is required`
                                    );
                                  }
                                }}
                              >
                                <option value="">
                                  Select Local Government
                                </option>

                                {LGAs
                                  ? LGAs.map((state) => {
                                      return (
                                        <option value={state}>{state}</option>
                                      );
                                    })
                                  : null}
                              </select>

                              {formik.errors.lga ? (
                                <div className="text-danger mt-2 fs-12">
                                  {formik.errors.lga}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}

                        {formik.values.user_type === "FELLOW" &&
                        !verify?.digitsVerified ? null : (
                          <>
                            <div className="new-account mt-2">
                              <p className="">
                                Already have an account?{" "}
                                <Link className="text-primary" to={Path.LOGIN}>
                                  Login here
                                </Link>
                              </p>
                            </div>

                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                              >
                                {registeringFellow || registeringProvider
                                  ? "Submitting"
                                  : "Register"}
                              </button>
                            </div>
                          </>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apply;

const ApplicationSchema = Yup.object().shape({
  user_type: Yup.string().required("Select Applicant Type"),
  email: Yup.string().when("user_type", {
    is: "FELLOW",
    then: () => Yup.string().email().required("Email is required"),
    otherwise: () => Yup.string().email(),
  }),
  first_name: Yup.string().when("user_type", {
    is: "FELLOW",
    then: () => Yup.string().required("First name is required"),
    otherwise: () => Yup.string(),
  }),
  last_name: Yup.string().when("user_type", {
    is: "FELLOW",
    then: () => Yup.string().required("Last name is required"),
    otherwise: () => Yup.string(),
  }),
  id_type: Yup.string().when("user_type", {
    is: "FELLOW",
    then: () => Yup.string().required("Select ID Type"),
    otherwise: () => Yup.string(),
  }),
  nin_bvn: Yup.string().when("user_type", {
    is: "FELLOW",
    then: () =>
      Yup.string()
        .required("ID number is required")
        .min(8, "Password must be at least 8 characters")
        .max(200, "Password can't be greater than 20 characters"),
    otherwise: () => Yup.string(),
  }),

  name: Yup.string().when("user_type", {
    is: "PROVIDER",
    then: () => Yup.string().required("Organisation name is required"),
    otherwise: () => Yup.string(),
  }),
  poc_fullname: Yup.string().when("user_type", {
    is: "PROVIDER",
    then: () => Yup.string().required("POC full name is required"),
    otherwise: () => Yup.string(),
  }),
  poc_email: Yup.string().when("user_type", {
    is: "PROVIDER",
    then: () => Yup.string().email().required("POC email is required"),
    otherwise: () => Yup.string().email(),
  }),
  poc_phone: Yup.string().when("user_type", {
    is: "PROVIDER",
    then: () => Yup.string().required("POC phone is required"),
    otherwise: () => Yup.string(),
  }),
  organisation_type: Yup.string().when("user_type", {
    is: "PROVIDER",
    then: () => Yup.string().required("Organisation type is required"),
    otherwise: () => Yup.string(),
  }),
  state: Yup.string().when("user_type", {
    is: "PROVIDER",
    then: () => Yup.string().required("State of training centre is required"),
    otherwise: () => Yup.string(),
  }),
  lga: Yup.string().when("user_type", {
    is: "PROVIDER",
    then: () => Yup.string().required("LGA of training centre is required"),
    otherwise: () => Yup.string(),
  }),
});
