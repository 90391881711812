/** @format */

import React, { useEffect, useState, Fragment } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { Path } from "utils/constant.data";
import Educat from "images/education-girl.png";
import Calpng from "images/vector/calpng.png";
import Book from "images/vector/book.png";

import ProviderLayout from "../Provider";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import CourseBlog from "../components/Dashboard/Dashboard/CourseBlog";
import { getProviderProfile } from "store/actions/profile";
import { Link } from "react-router-dom";
import { Card, Col, ListGroup } from "react-bootstrap";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
// import { Alert } from "react-bootstrap";
import { _listItem } from "utils/strings";
import ProviderStatus from "./components/ProviderStatus";
import CarouselComponent from "views/fellows/pages/Dashboard/components/Carousel";

// import { ReactComponent as InfoIcon } from "images/svg/info.svg";

const ProgressChart = loadable(() =>
  pMinDelay(import("../components/Dashboard/Dashboard/ProgressChart"), 1000)
);

const ProviderHome = () => {
  const dispatch = useDispatch();
  const [showApplication, setShowApplication] = useState(false);
  const profileState = useSelector((state) => state.profile);

  const profile = profileState?.profileInfo?.data?.profile
    ? profileState?.profileInfo?.data?.profile
    : null;

  useEffect(() => {
    getProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let getProfileInfo = () => {
    dispatch(getProviderProfile()).then((res) => {
      if (
        res?.data?.profile?.status !== "Application Completed" &&
        res?.data?.profile?.status === "New"
      ) {
        setShowApplication(true);
      }
    });
  };

  const [dropSelect, setDropSelect] = useState("This Month");

  // console.log("profile,profile", profileState?.profileInfo?.data?.profile?.poc_fullname);
  // console.log("profile,profile", profileState?.profileInfo?.data?.profile?.name);

  return (
    <ProviderLayout>
      {profileState?.isLoading ? (
        <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
          <FadeLoader color="#36d7b7" />
        </div>
      ) : (
        <div className="row w-100">
          {/* <div className="col-xl-12 col-xxl-12 col-lg-12">
            {(profileState?.profileInfo?.data?.profile?.status?.toLowerCase() !==
              "application_approved" ||
              profileState?.profileInfo?.data?.profile?.status?.toLowerCase() !==
                "selected") && (
              <Alert variant="info" className="alert-dismissible fade show">
                <InfoIcon />
                {`Upon completion of applications, our selection committee will
                 review all shortlisted applications and selected training
                 providers for the first phase will be informed before November
                 15th, 2023`}
              </Alert>
            )}
          </div> */}

          <div className="col-xl-12 col-xxl-12">
            <div className="row">
              <div className="col-xl-6 col-xxl-6">
                <div className="card dlab-join-card h-auto">
                  <div className="card-body">
                    <div className="dlab-media d-flex justify-content-between">
                      <div className="dlab-content provider__heading">
                        <h4>Welcome to your 3MTT Dashboard,{profile?.name}</h4>

                        <p>
                          We look forward to collaborating with you in achieving
                          our vision of training and placing 3M technical
                          talents.
                        </p>
                      </div>
                      <div className="dlab-img">
                        <img src={Educat} alt="" />
                      </div>
                      <div className="dlab-icon">
                        <img src={Calpng} alt="" className="cal-img" />
                        <img src={Book} alt="" className="book-img" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-xxl-6">
                <div className="card score-active">
                  <div className="card-header border-0 flex-wrap">
                    <h4>3MTT News</h4>
                  </div>
                  <div className="card-body pb-1 custome-tooltip style-1 py-0 ">
                    <div className="d-flex justify-content-center">
                      <BagIcon />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p>There is no 3MTT news yet</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 bt-order">
                <CourseBlog />
              </div>
              <div className="col-xl-12 bt-order">
                <CarouselComponent />
              </div>
            </div>
          </div>

          <ProviderStatus />

          <div className="col-xl-12 col-xxl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="row">
                  <div className="col-xl-6 col-xxl-6">
                    <div className="card score-active">
                      <div className="card-header border-0 pb-2 flex-wrap">
                        <h4>Training Activity</h4>
                        <ul className="d-flex"></ul>
                        <div className="d-flex align-items-center">
                          <Dropdown className="select-dropdown me-2">
                            <Dropdown.Toggle
                              as="div"
                              className="i-false dashboard-select  selectBtn btn-dark"
                            >
                              {dropSelect}{" "}
                              <i className="fa-solid fa-angle-down ms-2" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setDropSelect("This Month")}
                              >
                                This Month
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setDropSelect("This Weekly")}
                              >
                                This Weekly
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setDropSelect("This Day")}
                              >
                                This Day
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      {/* <div className="card-body pb-1 custome-tooltip pt-0">
                        <ScoreActivityChart />
                      </div> */}
                    </div>
                  </div>
                  <div className="col-xl-6 col-xxl-6">
                    <div className="card score-active">
                      <div className="card-header flex-wrap">
                        <h4>3MTT Resources</h4>
                        <ul className="d-flex"></ul>
                      </div>
                      {/* <div className="card-body pb-1 custome-tooltip style-1 py-0 "> */}
                      {/* <LearningActivityChart /> */}
                      <Col xl="12">
                        <Card className="shadow-none">
                          <Card.Body className="pt-0">
                            <ListGroup variant="flush">
                              {_listItem.map((list) => (
                                <Fragment key={list.title}>
                                  <ListGroup.Item
                                    target="_blank"
                                    href={list.link}
                                    as="a"
                                    className="link-hover"
                                  >
                                    {list.title}
                                  </ListGroup.Item>
                                </Fragment>
                              ))}
                            </ListGroup>
                          </Card.Body>
                        </Card>
                      </Col>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-6">
					  <div className="card">
						<div className="card-body pt-3">
						  <ProgressChart />
						  <div className="redial-content">
							<h4>Provider Progress</h4>
							<p>Lorem ipsum dolor sit amet, consectetur</p>
							<Link
							  to={"./course-details-2"}
							  className="btn btn-primary"
							>
							  More Details
							</Link>
						  </div>
						</div>
					  </div>
					</div>
					<div className="col-xl-8 col-lg-6">
					  <div className="card">
						<div className="card-body card-calendar home-calendar">
						  <CalendarBlog />
						  <h2>CalendarBlog </h2>
						</div>
					  </div>
					</div> */}
            </div>
          </div>

          {/* Application Modal */}
          <Modal className="modal fade" show={showApplication} size="sm">
            <div className="modal-content">
              <div className="modal-header border-0 justify-content-center">
                <h5 className="modal-title">Complete Your Application</h5>
              </div>
              <div className="modal-body py-0 text-center border-0 shadow-0">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <ProgressChart
                      percent={
                        profileState?.profileInfo?.data?.profile
                          ?.percentage_filled
                      }
                    />
                    <div className=" mb-3">
                      <p>
                        Welcome to the 3MTT Programme. To proceed further,
                        kindly complete your application.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal.Footer className="border-0 justify-content-center">
              <Link className="ai-icon" to={Path.PROVIDER_PROFILE}>
                <Button variant="primary">Complete Your Application</Button>
              </Link>
            </Modal.Footer>
          </Modal>

          
        </div>
      )}
    </ProviderLayout>
  );
};

export default ProviderHome;
