/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { Card, Col } from "react-bootstrap";
import { ReactComponent as BagIcon } from "images/svg/bag.svg";
import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { capitalize } from "utils/strings";

const MyProviderCard = () => {
  const { profileInfo } = useSelector(profileSelector);
  const _profile = profileInfo?.data?.profile ?? null;

  return (
    <Col xl="6">
      <Card>
        <Card.Header className="d-block">
          <Card.Title>My Applied Learning Cluster</Card.Title>
          <Card.Text className="m-0 subtitle"></Card.Text>
        </Card.Header>
        {_profile?.fellow_provider?.name ? (
          <Card.Body>
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h4 className="mb-0">
                  {_profile?.cohort?.name} {_profile?.cohort?.rank}
                </h4>
              </div>
            </div>
           
            <div className="ms-2 d-flex flex-column">
              <h4>{_profile?.fellow_provider?.name}</h4>
              <h6>Applied Learning Cluster ID:{_profile?.fellow_provider?.provider_id}</h6>
              <h6>Phone Number:{_profile?.fellow_provider?.phone_number}</h6>
              <h6>Email: {capitalize(_profile?.fellow_provider?.email)}</h6>
              <h6>
                Address:{" "}
                {capitalize(_profile?.fellow_provider?.address ?? "Nill")}
              </h6>
              <h6>
                ALC Whatsapp group:{" "}
                {
                  _profile?.fellow_provider?.whatsapp_group_link ? (
                    <a href={_profile?.fellow_provider?.whatsapp_group_link} className="text-primary">Join here</a>
                  ) : (
                    <span>Not available</span>
                  )
                }
              </h6>
              {
                _profile?.fellow_provider?.map_location && (
                  <a href={_profile?.fellow_provider?.map_location} target="blank">
                  <button
                  type="button"
                  className='btn btn-primary'
                >
                  Locate your ALC
                </button>
                </a>

                )
              }
            </div>
          </Card.Body>
        ) : (
          <Card.Body>
            <div className="d-flex justify-content-center">
              <BagIcon />
            </div>
            <div className="d-flex justify-content-center">
              <p>You have no training provider assigned yet</p>
            </div>
          </Card.Body>
        )}
      </Card>
    </Col>
  );
};

export default MyProviderCard;
