/** @format */

import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LocalGov } from "utils/constant.data";

import { useSelector } from "react-redux";
import { profileSelector } from "store/reducers/ProfileReducer";
import { useUpdateProviderProfileMutation } from "store/services/adminAPI";
import Swal from "sweetalert2";
import { extractErrorMessage, formatUnderScore, replaceUnderscoresWithSpaces } from "utils/strings";
import { toast } from "react-toastify";
// import { getProviderProfile } from "store/actions/profile";

import ProfileLevelOne from "./Levels/level1";
import ProfileLevelTwo from "./Levels/level2";
import ProfileLevelThree from "./Levels/level3";
import ProfileLevelFour from "./Levels/level4";
import ProfileLevelFive from "./Levels/level5";
import ProfileLevelSix from "./Levels/level6";
import ProfileLevelSeven from "./Levels/level7";

const ProfileForm = () => {
  const [formStep, setStep] = useState(1);
  const [LGAs, setLGAs] = useState([]);
  const [customError, setCustomError] = useState({
    lga: "",
    organisation_type: "",
  });
  const [courses, setCourses] = useState([]);
  const { profileInfo, isLoading } = useSelector(profileSelector);
  const [updateProviderProfile, { isLoading: updating }] =
    useUpdateProviderProfileMutation();

  // let dispatch = useDispatch();

  const steps = [
    {
      id: 1,
      name: " Organisation Data",
    },
    {
      id: 2,
      name: "Training Track Record",
    },
    {
      id: 3,
      name: "Training Curriculum",
    },
    {
      id: 4,
      name: "Faculty Relevant Experience",
    },
    {
      id: 5,
      name: "Training Infrastructure",
    },
    {
      id: 6,
      name: "Job Placements",
    },
    {
      id: 7,
      name: "Monitoring & Evaluation",
    },
  ];

  const handleSubmit = async (values, level, currentStep) => {

    const selectedCourses = courses?.join(",");
    const filteredValues = Object.keys(values)
    .filter(key => level.includes(key))
    .reduce((obj, key) => {
      obj[key] = values[key];
      return obj;
    }, {});

    Swal.fire({
      title: "",
      iconHtml: `<i class="fa fa-info-circle text-greyy" aria-hidden="true"></i>`,
      text: "Are you sure you want to proceed?",
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      customClass: {
        icon: "no-border",
      },
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await updateProviderProfile({
          ...filteredValues,
          courses: selectedCourses,
        })
          .unwrap()
          .then((data) => {
            // dispatch(getProviderProfile()).then(() => {});
            toast.success(`✔️ Application updated succesfully!`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setStep(currentStep)
          })
          .catch((error) => {
            const formattedError = extractErrorMessage(error)
            Swal.fire({
              title: replaceUnderscoresWithSpaces(error?.data?.code),
              iconHtml: ` <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>`,
              text: formattedError,
              showCloseButton: true,
              customClass: {
                icon: "no-border",
              },
              confirmButtonText: "Try Again",
            });
          });
      }
    });
  };

  const profile = profileInfo?.data?.profile
    ? profileInfo?.data?.profile
    : null;

  const formik = useFormik({
    initialValues: {
      name: profile ? profile?.name : "",
      state: profile ? profile?.state : "",
      lga: profile ? profile?.lga : "",
      address: profile ? profile?.address : "",
      organisation_type: profile ? profile?.organisation_type : "",
      website: profile ? profile?.website : "",
      cac: profile ? profile?.cac : "",
      social_media: profile ? profile?.social_media : "",
      poc_fullname: profile ? profile?.poc_fullname : "",
      poc_email: profile ? profile?.poc_email : "",
      poc_phone: profile ? profile?.poc_phone : "",

      training_period: profile ? profile?.training_period : "",
      staff_count: profile ? profile?.staff_count : "",
      track_record_of_previous_training: profile
        ? profile?.track_record_of_previous_training
        : "",

      courses: profile ? profile?.courses : "",
      trainee_capacity_track_record: profile
        ? profile?.trainee_capacity_track_record
        : "",
      courses_track_record: profile ? profile?.courses_track_record : "",
      courses_overview: profile ? profile?.courses_overview : "",
      courses_link: profile ? profile?.courses_link : "",

      core_team_and_skill_set: profile ? profile?.core_team_and_skill_set : "",

      // infrastructure_proof: profile ? profile?.infrastructure_proof : "",
      // infrastructure_proof_partner: profile
      //   ? profile?.infrastructure_proof_partner
      //   : "",
      // trainee_capacity: profile ? profile?.trainee_capacity : "",

      existing_placement_plan: profile ? profile?.existing_placement_plan : "",

      monitoring_and_evaluation: profile
        ? profile?.monitoring_and_evaluation
        : "",

      technology_breakdown: profile ? profile?.technology_breakdown : "",
      curicullum: profile ? profile?.curicullum : "",
      available_training_state: profile ? profile?.available_training_stat : "",
      training_approach: profile ? profile?.training_approach : "",
      cohort_capacity: profile ? profile?.cohort_capacity : "",
      skill_development_benchmarks: profile
        ? profile?.skill_development_benchmarks
        : "",

      accreditation_certification: profile
        ? profile?.accreditation_certification
        : "",
      professional_profiles: profile ? profile?.professional_profiles : "",
      infrastructure_physical_proof: profile
        ? profile?.infrastructure_physical_proof
        : "",
      infrastructure_technological_proof: profile
        ? profile?.infrastructure_technological_proof
        : "",

      plan_facilitating_job_placements: profile
        ? profile?.plan_facilitating_job_placements
        : "",
      partnerships_for_job_placements: profile
        ? profile?.partnerships_for_job_placements
        : "",
      approach_to_assessing_skill: profile
        ? profile?.approach_to_assessing_skill
        : "",
      tools_and_method: profile ? profile?.tools_and_method : "",
      organisation_email: profile?.organisation_email ?? "",
      organisation_phone_number: profile?.organisation_phone_number ?? "",
      whatsapp_group_link: profile?.whatsapp_group_link ?? "",
      map_location: profile?.map_location ?? "",
    },
    validationSchema: FormSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const validateFormStep = (level) => {
    let errorExist = false;
    Object.keys(formik?.values).forEach((key) => {
      if (level.includes(key)) {
        if (formik?.values[key]?.length < 1) {
          errorExist = true;
          formik.setFieldError(key, `${formatUnderScore(key)} is required`);
        }
      } else {
        formik.setFieldError(key, "");
      }
    });
    return errorExist;
  };

  useEffect(() => {
    if (profileInfo?.data?.profile) {
      const selectedState = profileInfo?.data?.profile?.state;
      const _selectedLGAs =
        LocalGov.find(
          (val) => val?.state?.toLowerCase() === selectedState.toLowerCase()
        )?.lgas || [];

      setLGAs([..._selectedLGAs]);

      const selectedCourses = profileInfo?.data?.profile?.courses;
      const words =
        selectedCourses?.length > 0 ? selectedCourses.split(",") : [];
      setCourses([...words]);
    }
  }, [profileInfo]);

  return (
    <Fragment>
      {profileInfo?.data && (
        <div className="profile-skills mb-5">
          {steps.map((step) => {
            return (
              <button
                key={step.name}
                className={`btn btn-primary ${
                  formStep === step.id ? "btn-primary activeBtn" : "btn-outline"
                }  btn-xs mb-1 me-1 pe-none user-select-none text-pigment-green`}
              >
                {step.name}
              </button>
            );
          })}

          {isLoading ? (
            <span
              role="status"
              aria-hidden="true"
              className="spinner-border spinner-border-sm ms-2 text-primary"
            ></span>
          ) : (
            <div className="row mx-0 my-5">
              <div className="col-xl-6 col-md-8">
                <form onSubmit={formik.handleSubmit}>
                  <h4 className="fs-26 font-w800 text-black mb-3 text-pigment-green">
                    {steps[formStep - 1]?.name}
                  </h4>

                  {/* <p>LGA:: {JSON.stringify(formik?.values?.lga)}</p> */}
                  {/* <p>{JSON.stringify(formik?.values?.organisation_type)}</p> */}
                  {/* <p>TOuched:: {JSON.stringify(formik?.touched)}</p> */}

                  {/* <p>Formik Errors:: {JSON.stringify(formik?.errors)}</p> */}
                  {/* <p>Errors:: {JSON.stringify(customError)}</p> */}

                  {formStep === 1 && (
                    <ProfileLevelOne
                      profile={profile}
                      formik={formik}
                      validateFormStep={validateFormStep}
                      setStep={setStep}
                      LGAs={LGAs}
                      setLGAs={setLGAs}
                      customError={customError}
                      setCustomError={setCustomError}
                      updating={updating}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {formStep === 2 && (
                    <ProfileLevelTwo
                      profile={profile}
                      formik={formik}
                      validateFormStep={validateFormStep}
                      setStep={setStep}
                      updating={updating}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {formStep === 3 && (
                    <ProfileLevelThree
                      profile={profile}
                      formik={formik}
                      validateFormStep={validateFormStep}
                      setStep={setStep}
                      courses={courses}
                      setCourses={setCourses}
                      updating={updating}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {formStep === 4 && (
                    <ProfileLevelFour
                      profile={profile}
                      formik={formik}
                      validateFormStep={validateFormStep}
                      setStep={setStep}
                      updating={updating}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {formStep === 5 && (
                    <ProfileLevelFive
                      profile={profile}
                      formik={formik}
                      validateFormStep={validateFormStep}
                      setStep={setStep}
                      updating={updating}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {formStep === 6 && (
                    <ProfileLevelSix
                      profile={profile}
                      formik={formik}
                      validateFormStep={validateFormStep}
                      setStep={setStep}
                      updating={updating}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {formStep === 7 && (
                    <ProfileLevelSeven
                      profile={profile}
                      formik={formik}
                      validateFormStep={validateFormStep}
                      setStep={setStep}
                      updating={updating}
                      handleSubmit={handleSubmit}
                    />
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ProfileForm;

Yup.addMethod(Yup.string, "wordLength", function (errorMessage, maxLength) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;
    const wordArray = value?.trim().split(/\s+/);

    return (
      (value && wordArray.length < maxLength) ||
      createError({ path, message: errorMessage })
    );
  });
});

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Organization name is required"),
  state: Yup.string().required("State is required"),
  lga: Yup.string().required("Local Governmnet is required"),
  address: Yup.string().required("Address is required"),
  organisation_type: Yup.string().required("Organisation type is required"),
  website: Yup.string(),
  // cac:Yup.string().required("CAC  is required"),
  social_media: Yup.string().required("Social Media is required"),
  poc_fullname: Yup.string().required("Contact Name is required"),
  poc_email: Yup.string().email("Invalid email address").required("POC Email is required"),
  poc_phone: Yup.number().required("POC phone number is required")
  .min(10, "POC phone number must be at least 10 characters")
  .typeError("POC phone Number must be a number"),
  training_period: Yup.string().required("Training period is required"),
  staff_count: Yup.string().required("Staff count is required"),
  track_record_of_previous_training: Yup.string()
    .required("Track record of previous training is required")
    .wordLength("Total word count cannot exceed 500", 501),
  courses: Yup.string().required("Courses are required"),
  trainee_capacity_track_record: Yup.string()
    .required("Capacity Track record is required")
    .wordLength("Total word count cannot exceed 500", 501),
  courses_track_record: Yup.string().required(
    "Courses Track record is required"
  ),
  courses_overview: Yup.string()
    .required("Courses overview is required")
    .wordLength("Total word count cannot exceed 500", 501),
  courses_link: Yup.string().required("Courses link is required"),
  core_team_and_skill_set: Yup.string()
    .required("Overview of your training delivery team is required")
    .wordLength("Total word count cannot exceed 500", 501),
  // infrastructure_proof: Yup.string()
  //   .required("Infrastructure proof is required")
  //   .wordLength("Total word count cannot exceed 500", 501),
  // infrastructure_proof_partner: Yup.string("Infrastructure Proof Partner")
  //   .required("Infrastructure proof partner is required")
  //   .wordLength("Total word count cannot exceed 500", 501),
  // trainee_capacity: Yup.string()
  //   .required("Trainee capacity is required")
  //   .wordLength("Total word count cannot exceed 500", 501),

  existing_placement_plan: Yup.string()
    .required("Placement Plan is required")
    .wordLength("Total word count cannot exceed 500", 501),
  monitoring_and_evaluation: Yup.string()
    .required("Monitoring and Evaluation technique is required")
    .wordLength("Total word count cannot exceed 500", 501),

  accreditation_certification: Yup.string()
    .required("Certification is required")
    .wordLength("Total word count cannot exceed 500", 501),

  professional_profiles: Yup.string()
    .required("Professional profile is required")
    .wordLength("Total word count cannot exceed 500", 501),

  infrastructure_physical_proof: Yup.string()
    .required("Infrastructure physical proof is required")
    .wordLength("Total word count cannot exceed 500", 501),
  infrastructure_technological_proof: Yup.string()
    .required("Infrastructure technological proof is required")
    .wordLength("Total word count cannot exceed 500", 501),

  plan_facilitating_job_placements: Yup.string()
    .required("Plan facilitating job placements is required")
    .wordLength("Total word count cannot exceed 500", 501),
  partnerships_for_job_placements: Yup.string()
    .required("Partnerships for job placements is required")
    .wordLength("Total word count cannot exceed 500", 501),

  approach_to_assessing_skill: Yup.string()
    .required("Approach to assessing skill is required")
    .wordLength("Total word count cannot exceed 500", 501),
  tools_and_method: Yup.string()
    .required("Tools and method is required")
    .wordLength("Total word count cannot exceed 500", 501),
  organisation_email: Yup.string().email("Invalid email address").required("Organisation Email is required"),
  organisation_phone_number: Yup.number().required("Organisation Phone Number is required")
  .min(10, "Organisation phone number must be at least 10 characters")
  .typeError("Organisation Phone Number must be a number"),
  whatsapp_group_link: Yup.string()
  .required('WhatsApp group link is required')
  .url('Please enter a valid URL')
  .test('is-whatsapp-url', 'URL must be a WhatsApp group link', 
    value => value && value.startsWith('https://chat.whatsapp.com/')
  )
});
