/** @format */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";





export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    // prepareHeaders: (headers) => {
    //   headers.set("Content-Type", "application/json");
    //   headers.set("PERSONIFY-KEY", 'BY#OZ80ldPVH+CG)`#uijWJbT$/g!ylTCIJ].BLZS+0xua6Z+!D_XwP7<uC{x@(');
    //   return headers;
    // },
  }),
  endpoints: (builder) => ({
    registerUserEmail: builder.mutation({
      query: (body) => ({
        url: "auth/register/session/initialize",
        method: "POST",
        body,
      }),
    }),
    setUserPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/register/session/set_password",
        method: "POST",
        body,
      }),
    }),
    verifyUserOTP: builder.mutation({
      query: (body) => ({
        url: "auth/register/session/confirm",
        method: "POST",
        body,
      }),
    }),
    loginUser: builder.mutation({
      query: (body) => ({
        url: "auth/login",
        method: "POST",
        body,
        headers: {
          
        }
      }),
    }),
    verifyUser: builder.query({
      query: (reference) => `payments/verify/${reference}`,
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        url: "auth/forgot_password/initialize",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "auth/forgot_password/confirm",
        method: "POST",
        body,
      }),
    }),
    resetUserEmail: builder.mutation({
      query: (body) => ({
        url: "auth/email/change/confirm",
        method: "POST",
        body,
      })
    }),
    resendOTP: builder.mutation({
      query: (body) => ({
        url: "/auth/register/session/otp/resend",
        method: "POST",
        body,
      }),
    }),
    sendOTPasPassword: builder.mutation({
      query: (body) => ({
        url: "/auth/register/session/otp/resend/sms",
        method: "POST",
        body,
      }),
    }),
    registerFellow: builder.mutation({
      query: (body) => ({
        url: "/auth/register/fellow",
        method: "POST",
        body,
      })
    }),
    registerProvider: builder.mutation({
      query: (body) => ({
        url: "/auth/register/provider",
        method: "POST",
        body,
      })
    }),
    validateID: builder.mutation({
      query: (body) => ({
        url: "/auth/validate/id",
        method: "POST",
        body,
      })
    }),
  }),
});

export const {
  useRegisterUserEmailMutation,
  useSetUserPasswordMutation,
  useVerifyUserOTPMutation,
  useLoginUserMutation,
  useVeifyUserQuey,
  useForgotPasswordMutation,
  useResetPasswordMutation,

  useResendOTPMutation,
  useSendOTPasPasswordMutation,
  useResetUserEmailMutation,
  useRegisterFellowMutation,
  useRegisterProviderMutation,
  useValidateIDMutation
} = authApi;
